import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import Login from './pages/Login.js';
import Home from './pages/Home.js';
import Detail from './pages/Detail.js';
import Assets from './pages/Assets.js';
import Cart from './pages/Cart.js';

function App() {
  return (
    <Router>
      <main className="main">
        <Routes>
          <Route path="/login" element={<Login />}/>
          <Route path="/" exact element={<Home />}/>
          <Route path="/detail/:id" element={<Detail />} />
          <Route path="/assets/:id" element={<Assets />}/>
          <Route path="/cart/:id" element={<Cart />}/>
        </Routes>
      </main>
    </Router>
  );
}

export default App;
