import { helix } from 'ldrs'

helix.register()

const Loading = () => {
  return (
	<div className="fixed w-screen h-screen flex justify-center items-center bg-black bg-opacity-50">
		<l-helix
		size="100"
		bg-opacity="0.1"
		speed="1.75" 
		color="#16a34a" 
		></l-helix>
	</div>
  )
};

export default Loading;