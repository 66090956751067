// src/context/AuthProvider.js
import React, { createContext, useContext } from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MICROSOFT_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_MICROSOFT_TENANT_ID}`,
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: process.env.REACT_APP_CACHE_LOCATION,
    storeAuthStateInCookie:
      process.env.REACT_APP_STORE_AUTH_STATE_IN_COOKIE === "true",
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

const AuthContext = createContext();

export const AuthProvider = ({ children }) => (
  <AuthContext.Provider value={msalInstance}>
    <MsalProvider instance={msalInstance}>{children}</MsalProvider>
  </AuthContext.Provider>
);

export const useAuth = () => useContext(AuthContext);
