import Request from '../utils/Request';
import axios from 'axios';

class AssetService {
    static async getAssets(start_date, end_date, query, page) {
        try {
            const data = {
                start_date : start_date,
                end_date : end_date,
                room_type_id : 8,
                query: query,
                page: page 
            }
            const response = await axios.post(`${Request.backend}assets`, data);
            console.log(response.data);
            return response.data;
        } catch (error) {
            console.error('Error fetching lecturers:', error);
            return null;
        } 
    }

    static async borrowAssets(data, token){
        return Request.make(data, 'assets/bookingAssets', token);
    }
}

export default AssetService;
