import React, { useEffect, useState } from "react";
import Header from "../components/Header.js";
import AssetService from "../services/AssetService.js";
import { useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import BorrowingService from "../services/BorrowingService.js";
import { useNavigate } from "react-router-dom";
import AuthService from "../services/AuthService.js";

const Assets = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  const [detail, setDetail] = useState([]);
  const [assets, setAssets] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [currentCart, setCurrentCart] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [authorized, setAuthorized] = useState(false);

  const fetchData = () => {
    BorrowingService.getDetail(id, cookies?.token)
      .then(async (result) => {
        const urlParams = new URLSearchParams(window.location.search);
        const searchValue = urlParams.get("search")
          ? urlParams.get("search")
          : "";
        const pageValue = urlParams.get("page") ? urlParams.get("page") : 1;
        setDetail(result.data);

        if (
          result.data.second_lecturer_email === null &&
          result.data.status !== "Approved"
        ) {
          navigate("/");
        } else if (
          result.data.second_lecturer_email !== null &&
          result.data.status !== "Approved" &&
          result.data.second_status !== "Approved"
        ) {
          navigate("/");
        }

        // if (new Date(result.data.check_out_date) <= new Date()) {
        //   navigate("/");
        // }

        AssetService.getAssets(
          result.data.check_out_date,
          result.data.check_in_date,
          searchValue,
          pageValue
        ).then((assets) => {
          console.log(assets);
          setAssets(assets.data);
          setLastPage(assets.last_page);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    AuthService.parseToken(cookies?.token).catch(() => {
      navigate("/login");
    });

    fetchData();

    const storedCart = localStorage.getItem("cart");
    if (storedCart) {
      setCurrentCart(JSON.parse(storedCart));
    }
  }, []);

  const handleAddToCart = (asset) => {
    setCurrentCart((prevCart) => {
      const updatedCart = [...prevCart, asset];
      localStorage.setItem("cart", JSON.stringify(updatedCart));
      return updatedCart;
    });
  };

  const handleRemoveToCart = (id) => {
    setCurrentCart((prevCart) => {
      const updatedCart = prevCart.filter((item) => item.id !== id);
      localStorage.setItem("cart", JSON.stringify(updatedCart));
      return updatedCart;
    });
  };

  useEffect(() => {
    const baseUrl = `/assets/${id}`;
    const params = new URLSearchParams();
    params.append("page", currentPage);
    params.append("search", search);

    const finalUrl = `${baseUrl}?${params.toString()}`;
    navigate(finalUrl);
  }, [currentPage, search]);

  const handleSearch = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    fetchData();
  };

  const handlePagination = (button) => (e) => {
    e.preventDefault();
    if (button === "FirstPage") setCurrentPage(1);
    if (button === "LastPage") setCurrentPage(lastPage);
    if (button === "Next") {
      if (currentPage < lastPage) setCurrentPage(currentPage + 1);
    }
    if (button === "Prev") {
      if (currentPage > 1) setCurrentPage(currentPage - 1);
    }

    fetchData();
  };

  return (
    <div className="relative">
      <div className="mx-[20px]">
        <Header setAuthorized={setAuthorized} />
        <section className="home section">
          <div className="container flex flex-col items-center">
            <div className="overflow-auto w-full rounded-lg block">
              <div className="flex flex-wrap justify-between items-center mb-3">
                <div className="">
                  <h1 className="text-xl font-semibold my-1 underline">
                    Choose Assets to Book
                  </h1>
                  <h1 className="text-sm font-semibold my-1">
                    Start Date: {detail.check_out_date}
                  </h1>
                  <h1 className="text-sm font-semibold my-1">
                    End Date: {detail.check_in_date}
                  </h1>
                </div>
                <div className="flex justify-center items-end mr-5">
                  <form className="relative w-max" onSubmit={handleSearch}>
                    <div className="relative w-[450px] h-[80px]">
                      <input
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                        value={search}
                        type="text"
                        placeholder="Search"
                        className="absolute top-[20px] box-border h-[43px] px-[20px] text-lg rounded-full outline-none border-2 border-green-500 transition-all duration-1000 ease-in-out"
                        style={{
                          right: `${isActive ? "45px" : "20px"}`,
                          width: `${isActive ? "350px" : "0"}`,
                        }}
                      />
                      <div
                        onClick={() => setIsActive((isActive) => !isActive)}
                        className={`absolute top-[10px] box-border w-[60px] h-[60px] bg-transparent-500 text-[#fff] px-[20px] cursor-pointer flex items-center outline-none text-lg rounded-full border-3 border-transparent-500 transition-all duration-1000 ease-in-out ${
                          isActive ? "right-[35px]" : "right-[12px]"
                        }`}
                        style={{
                          transform: isActive ? "rotate(-360deg)" : "none",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="3"
                          stroke="#22c55e"
                          className="w-6 h-6"
                        >
                          <path d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                        </svg>
                      </div>
                    </div>
                  </form>
                  <a href={`/cart/${id}`}>
                    <div className="relative h-[80px] flex justify-content items-center">
                      <div className="box-border w-[43px] h-[43px] bg-transparent-500 text-[#fff] cursor-pointer flex items-center justify-center outline-none text-lg rounded-full border-2 border-green-500 transition-all duration-1000 ease-in-out ${isActive ? 'right-[40px]' : 'right-[17px]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="2.5"
                          stroke="#22c55e"
                          className="w-6 h-6"
                        >
                          <path d="M15.75 10.5V6a3.75 3.75 0 1 0-7.5 0v4.5m11.356-1.993 1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 0 1-1.12-1.243l1.264-12A1.125 1.125 0 0 1 5.513 7.5h12.974c.576 0 1.059.435 1.119 1.007ZM8.625 10.5a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm7.5 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                        </svg>
                      </div>
                      <span className="absolute top-4 -right-1 bg-red-500 text-white text-xs w-4 h-4 flex items-center justify-center rounded-full">
                        {currentCart?.length ? currentCart.length : 0}
                      </span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <hr className="w-full border-[1px] mb-7" />

            <div className="flex flex-wrap gap-10 my-8 justify-center items-center">
              {assets.map((asset) => (
                <div
                  className="relative w-fit max-w-[280px] h-[35rem] bg-white border border-gray-200 rounded-lg shadow flex flex-col items-center"
                  key={asset.id}
                >
                  <a href="#">
                    <img
                      className="p-0 rounded-t-lg w-full max-h-[25rem]"
                      src={
                        asset?.images[0]
                          ? asset.images[0].path
                          : "/assets/noImage.jpg"
                      }
                      alt="product image"
                    />
                  </a>
                  <div className="px-5 py-5">
                    <div href="#">
                      <h5 className="text-md font-semibold tracking-tight text-gray-500">
                        #{asset.id} / {asset.product_number}
                      </h5>
                    </div>
                    <div href="#">
                      <h5 className="text-lg font-semibold tracking-tight text-gray-900">
                        {asset.name}
                      </h5>
                    </div>
                    <div href="#">
                      <h5 className="text-sm font-semibold tracking-tight text-gray-400">
                        Created: {asset.created_at}
                      </h5>
                    </div>
                  </div>
                  {!currentCart?.some((item) => item.id === asset.id) && (
                    <div
                      onClick={() => handleAddToCart(asset)}
                      className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br font-medium rounded-lg text-sm px-10 py-2.5 text-center m-2 absolute bottom-1 right-1 cursor-pointer"
                    >
                      + Cart
                    </div>
                  )}
                  {currentCart?.some((item) => item.id === asset.id) && (
                    <div
                      onClick={() => handleRemoveToCart(asset.id)}
                      className="text-red-500 font-medium rounded-lg text-sm px-2 py-1.5 text-center m-2 absolute bottom-1 right-1 cursor-pointer"
                    >
                      Remove from Cart
                    </div>
                  )}
                </div>
              ))}
            </div>

            <nav aria-label="Page navigation example ">
              <ul className="inline-flex flex-wrap justify-center -space-x-px text-base h-10 gap-2">
                <li>
                  <div
                    onClick={handlePagination("FirstPage")}
                    className="flex items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 cursor-pointer"
                  >
                    &lt;&lt; First Page
                  </div>
                </li>
                <li>
                  <div
                    onClick={handlePagination("Prev")}
                    className="flex items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 cursor-pointer"
                  >
                    &lt; Previous
                  </div>
                </li>
                <li>
                  <div className="flex items-center justify-center px-4 h-10 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 text-blue-600 rounded-lg">
                    {currentPage}
                  </div>
                </li>
                <li>
                  <div
                    onClick={handlePagination("Next")}
                    className="flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 cursor-pointer"
                  >
                    Next &gt;
                  </div>
                </li>
                <li>
                  <div
                    onClick={handlePagination("LastPage")}
                    className="flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 cursor-pointer"
                  >
                    LastPage &gt;&gt;
                  </div>
                </li>
              </ul>
            </nav>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Assets;
