import axios from "axios";

export default class Request {
  static backend = "https://greenlit.apps.binus.ac.id/api/";
  // static backend = "http://127.0.0.1:5000/api/";

  static async make(data = {}, api_endpoint = "bridge", token) {
    console.log("API ENDPOINT: " + api_endpoint);
    let header = {
      "Content-Type": "multipart/form-data",
    };
    if (token !== null) {
      token = token.replace('"', "");
      header["Authorization"] = `${token}`;
    }

    console.log(...data);
    console.log(data.get("lecturer_email"));

    try {
      const response = await axios.post(this.backend + api_endpoint, data, {
        headers: header,
      });
      return response.data;
    } catch (error) {
      console.error("Error uploading data:", error);
      throw error;
    }
  }
}
