import React, { useEffect, useState } from "react";
import Header from "../components/Header.js";
import NewForm from "../components/NewForm.js";
import { useNavigate } from "react-router-dom";
import BorrowingService from "../services/BorrowingService.js";
import { useCookies } from "react-cookie";
import AuthService from "../services/AuthService.js";

const Home = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  const [borrowingList, setBorrowingList] = useState([]);
  const [successAlert, setSuccessAlert] = useState(false);
  const [authorized, setAuthorized] = useState(false);

  useEffect(() => {
    localStorage.removeItem("cart");

    AuthService.parseToken(cookies?.token).catch(() => {
      navigate("/login");
    });
  }, []);

  useEffect(() => {
    console.log(cookies?.token);
    BorrowingService.getBorrowingList(cookies?.token)
      .then((result) => {
        console.log(result);
        setBorrowingList(result);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [open]);

  useEffect(() => {
    let timeoutId;
    if (successAlert) {
      timeoutId = setTimeout(() => {
        setSuccessAlert(false);
      }, 3000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [successAlert]);

  const handleRowClick = (id) => {
    navigate(`/detail/${id}`);
  };

  return (
    <div className="relative">
      <div className="mx-[20px]">
        <Header setAuthorized={setAuthorized} />
        <section className="home section">
          <div className="container flex flex-col items-end">
            {!authorized && (
              <div
                className="bg-blue-200 hover:bg-blue-300 text-blue-800 font-bold py-2 px-4 rounded m-2 hover:cursor-pointer"
                onClick={() => setOpen(true)}
              >
                + New
              </div>
            )}

            {/* Table */}
            <div className="overflow-auto max-w-full w-full rounded-lg hidden md:block h-[70vh] scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar scrollbar-thumb-gray-200 scrollbar-track-white">
              <table className="w-full ">
                <thead className="bg-gray-50 border-b-2 border-gray-200">
                  <tr>
                    <th className="p-3 text-sm font-semibold tracking-wide text-left">
                      Id
                    </th>
                    <th className="p-3 text-sm font-semibold tracking-wide text-left">
                      First Lecturer
                    </th>
                    <th className="p-3 text-sm font-semibold tracking-wide text-left">
                      Second Lecturer
                    </th>
                    <th className="p-3 text-sm font-semibold tracking-wide text-left">
                      Student
                    </th>
                    <th className="p-3 text-sm font-semibold tracking-wide text-left">
                      Meeting Number
                    </th>
                    <th className="p-3 text-sm font-semibold tracking-wide text-left">
                      Check Out Date
                    </th>
                    <th className="p-3 text-sm font-semibold tracking-wide text-left">
                      Check In Date
                    </th>
                    <th className="p-3 text-sm font-semibold tracking-wide text-left">
                      Status
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {borrowingList?.map((item, index) => {
                    let status = "";
                    if (item.second_lecturer_email) {
                      if (
                        item.status === "Approved" &&
                        item.second_status === "Approved"
                      )
                        status = "Approved";
                      else if (
                        item.status === "Rejected" ||
                        item.second_status === "Rejected"
                      )
                        status = "Rejected";
                      else if (
                        item.status !== "Rejected" &&
                        item.status === "Approved" &&
                        item.status == "Pending"
                      )
                        status = item.status;
                      else status = "Pending";
                    } else if (!item.second_lecturer_email) {
                      status = item.status;
                    }

                    return (
                      <tr
                        className="border-b-2 cursor-pointer hover:bg-green-50"
                        key={index}
                        onClick={() => handleRowClick(item.id)}
                      >
                        <td className="p-3 text-sm tracking-wide whitespace-normal break-words">
                          {item.id}
                        </td>
                        <td className="p-3 text-sm tracking-wide whitespace-nowrap">
                          {item.lecturer_email}
                        </td>
                        <td className="p-3 text-sm tracking-wide whitespace-nowrap">
                          {item.second_lecturer_email}
                        </td>
                        <td className="p-3 text-sm tracking-wide whitespace-nowrap">
                          {item.student_email}
                        </td>
                        <td className="p-3 text-sm tracking-wide whitespace-nowrap">
                          {item.meeting_number}
                        </td>
                        <td className="p-3 text-sm tracking-wide whitespace-nowrap">
                          {item.check_out_date}
                        </td>
                        <td className="p-3 text-sm tracking-wide whitespace-nowrap">
                          {item.check_in_date}
                        </td>
                        <td className="p-3 text-sm tracking-wide whitespace-nowrap">
                          {status === "Approved" && (
                            <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-green-800 bg-green-200 rounded-lg bg-opacity-50">
                              Approved
                            </span>
                          )}
                          {status === "Rejected" && (
                            <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-red-800 bg-red-200 rounded-lg bg-opacity-50">
                              Rejected
                            </span>
                          )}
                          {status === "Pending" && (
                            <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-yellow-800 bg-yellow-200 rounded-lg bg-opacity-50">
                              Pending
                            </span>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="grid grid-cols-1 gap-4 md:hidden w-full overflow-auto max-h-[70vh]">
              {borrowingList?.map((item, index) => {
                let status = "";
                if (item.second_lecturer_email) {
                  if (
                    item.status === "Approved" &&
                    item.second_status === "Approved"
                  )
                    status = "Approved";
                  else if (
                    item.status === "Rejected" ||
                    item.second_status === "Rejected"
                  )
                    status = "Rejected";
                  else status = "Pending";
                } else if (!item.second_lecturer_email) {
                  status = item.status;
                }

                return (
                  <div
                    className="bg-gray-200 p-4 rounded-lg bg-opacity-50 h-fit"
                    key={index}
                    onClick={() => handleRowClick(item.id)}
                  >
                    <div className="flex items-center space-x-2 text-sm">
                      <div className="font-bold">{item.id}</div>
                      {status === "Approved" && (
                        <div className="p-1.5 text-xs font-medium uppercase tracking-wider text-green-800 bg-green-200 rounded-lg bg-opacity-50">
                          {item.status}
                        </div>
                      )}
                      {status === "Rejected" && (
                        <div className="p-1.5 text-xs font-medium uppercase tracking-wider text-red-800 bg-red-200 rounded-lg bg-opacity-50">
                          {item.status}
                        </div>
                      )}
                      {status === "Pending" && (
                        <div className="p-1.5 text-xs font-medium uppercase tracking-wider text-yellow-800 bg-yellow-200 rounded-lg bg-opacity-50">
                          {item.status}
                        </div>
                      )}
                    </div>
                    <div>
                      <span className="text-gray-500">First Lecturer: </span>
                      {item.lecturer_email}
                    </div>
                    <div>
                      <span className="text-gray-500">Second Lecturer: </span>
                      {item.second_lecturer_email}
                    </div>
                    <div>
                      <span className="text-gray-500">Student: </span>
                      {item.student_email}
                    </div>
                    <div>
                      <span className="text-gray-500">Meeting Number: </span>{" "}
                      {item.meeting_number}{" "}
                    </div>
                    <div>
                      <span className="text-gray-500">Check Out: </span>
                      {item.check_out_date}
                    </div>
                    <div>
                      <span className="text-gray-500">Check In: </span>
                      {item.check_in_date}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </div>
      {successAlert && (
        <div className="fixed bottom-0 left-0 right-0 flex justify-center">
          <div className="bg-green-200 px-6 py-4 mx-2 my-4 rounded-md text-lg flex items-center mx-auto max-w-lg">
            <svg
              viewBox="0 0 24 24"
              className="text-green-600 w-5 h-5 sm:w-5 sm:h-5 mr-3"
            >
              <path
                fill="currentColor"
                d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
              ></path>
            </svg>
            <span className="text-green-800">Sent Successfully.</span>
          </div>
        </div>
      )}
      {open && (
        <div
          className="absolute w-full h-screen flex justify-center items-center top-0 bg-black bg-opacity-50"
          onClick={() => setOpen(false)}
        >
          <div className="relative" onClick={(e) => e.stopPropagation()}>
            <NewForm setOpenForm={setOpen} setSuccessAlert={setSuccessAlert} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
