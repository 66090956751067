import Request from "../utils/Request";
import axios from "axios";

class BorrowingService {
  static async getBorrowingList(token) {
    try {
      let header = {};
      if (token) {
        header["Authorization"] = `${token}`;
      }

      const response = await axios.get(`${Request.backend}borrowing`, {
        headers: header,
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching borrowing list:", error);
      return null;
    }
  }

  static async newBorrowing(data, token) {
    console.log(...data);
    try {
      return Request.make(data, "borrowing/newBorrwoing", token);
    } catch (error) {
      console.error("Error uploading data:", error);
      throw error;
    }
  }

  static async getDetail(id, token) {
    let header = {};
    if (token) {
      header["Authorization"] = `${token}`;
    }

    return await axios.get(`${Request.backend}borrowing/detail/` + id, {
      headers: header,
    });
  }

  static async updateStatusBorrowing(data, token) {
    let header = {};
    if (token) {
      header["Authorization"] = `${token}`;
    }

    let newData = {};
    if (data.firstSecondLecturer == 1) {
      newData = {
        status: data.status,
        status_reason: data.status_reason,
      };
    } else if (data.firstSecondLecturer == 2) {
      newData = {
        second_status: data.status,
        second_status_reason: data.status_reason,
      };
    }
    return await axios.put(
      `${Request.backend}borrowing/updateStatus/` + data.borrowing_id,
      newData,
      {
        headers: header,
      }
    );
  }
}

export default BorrowingService;
