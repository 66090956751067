import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import AuthService from "../services/AuthService";
import { useMsal } from "@azure/msal-react";

const Header = ({ setAuthorized }) => {
  const { instance, accounts, inProgress } = useMsal();
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  const [displayName, setDisplayName] = useState("");

  useEffect(() => {
    console.log(cookies.token);
    AuthService.parseToken(cookies.token)
      .then((result) => {
        console.log(result.whitelist_status);
        console.log(result.whitelist_status == "authorized");
        setAuthorized(result.whitelist_status == "authorized");
        setDisplayName(result.User.name);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleLogut = async (e) => {
    e.preventDefault();

    setCookie("token", { expires: new Date(Date.now() - 1) });
    removeCookie("token");
    localStorage.removeItem("cart");
    
    try {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/login",
      });
    } catch (error) {
      console.error("Failed to logout:", error);
    }
    navigate("/login");
  };

  return (
    <header className="w-full header">
      <nav className="h-[4.5rem] flex justify-between items-center gap-x-4 nav container">
        <a href="/" className="relative font-600 text-2xl">
          <span className="text-green-500">Green</span>
          <span className="text-gray-500">lit</span>
          <span className="text-green-500">.</span>
        </a>

        <div className="nav-item flex flex-col text-right">
          <span className="nav-title text-md">Hello, {displayName}</span>
          <div onClick={handleLogut} className="nav-link">
            <span className="nav-title text-sm text-gray-500 cursor-pointer">
              Logout
            </span>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
