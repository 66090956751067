import React, { useEffect, useState } from "react";
import Header from "../components/Header.js";
import NewForm from "../components/NewForm.js";
import { useNavigate, useParams } from "react-router-dom";
import BorrowingService from "../services/BorrowingService.js";
import { useCookies } from "react-cookie";
import AuthService from "../services/AuthService.js";

const Detail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(["token", "cart"]);
  const [detail, setDetail] = useState([]);
  const [statusReason, setStatusReason] = useState("");
  const [status, setStatus] = useState("");
  const [authorized, setAuthorized] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [firstSecondLecturer, setFirstSecondLecturer] = useState(0);

  const fetchData = async () => {
    BorrowingService.getDetail(id, cookies?.token)
      .then(async (result) => {
        console.log(result.data);
        setDetail(result.data);
        if (result.data.second_lecturer_email) {
          if (
            result.data.status === "Approved" &&
            result.data.second_status === "Approved"
          )
            setStatus("Approved");
          else if (
            result.data.status === "Rejected" ||
            result.data.second_status === "Rejected"
          )
            setStatus("Rejected");
          else if (
            result.data.status !== "Rejected" &&
            result.data.status === "Approved" &&
            result.data.status == "Pending"
          )
            setStatus(result.data.status);
          else setStatus("Pending");
        } else if (!result.data.second_lecturer_email) {
          setStatus(result.data.status);
        }

        const currUser = await AuthService.parseToken(cookies.token);
        if (result.data.second_lecturer_email == currUser.User.email)
          setFirstSecondLecturer(2);
        else if (result.data.lecturer_email == currUser.User.email)
          setFirstSecondLecturer(1);
        console.log(detail?.bookings);
        console.log(new Date(detail.check_out_date));
        console.log(new Date());
        console.log(new Date(detail.check_out_date) <= new Date());
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    AuthService.parseToken(cookies?.token).catch(() => {
      navigate("/login");
    });

    setCookie("cart", { expires: new Date(Date.now() - 1) });
    removeCookie("cart");

    fetchData();
  }, []);

  const handleSubmit = async (status) => {
    if (statusReason == "") {
      setErrorMessage(true);
      return;
    }
    setErrorMessage(false);

    const data = {
      status: status,
      status_reason: statusReason,
      borrowing_id: id,
      firstSecondLecturer: firstSecondLecturer,
    };

    BorrowingService.updateStatusBorrowing(data, cookies?.token).then(() => {
      fetchData();
    });

    console.log("Status Reason:", statusReason);
    console.log("Status:", status);
  };

  const downloadFile = (url) => {
    window.open(url, "_blank");
  };

  const downloadAllFiles = () => {
    try {
      console.log(detail?.document_path);
      const separatedPaths = JSON.parse(detail?.document_path);
      if (Array.isArray(separatedPaths)) {
        separatedPaths.forEach((url) => downloadFile(url));
      } else {
        console.error("document_path is not an array:", separatedPaths);
      }
    } catch (error) {
      console.error("Failed to parse document_path:", error);
    }
  };

  return (
    <div className="relative">
      <div className="mx-[20px]">
        <Header setAuthorized={setAuthorized} />
        <section className="home section">
          <div className="container flex flex-col items-center">
            <div className="w-full flex flex-wrap gap-10">
              <dl className="max-w-md text-gray-900 divide-y divide-gray-200">
                <div className="flex flex-col pb-3">
                  <dt className="mb-1 text-gray-500 md:text-sm">Id</dt>
                  <dd className="text-sm font-semibold">{id}</dd>
                </div>
                <div className="flex flex-col py-3">
                  <dt className="mb-1 text-gray-500 md:text-sm">
                    First Lecturer
                  </dt>
                  <dd className="text-sm font-semibold">
                    {detail.lecturer_email}
                    {detail.status === "Approved" && (
                      <span className="p-1.5 text-xs font-medium uppercase tracking-wider ml-2 text-green-800 bg-green-200 rounded-lg bg-opacity-50">
                        Approved
                      </span>
                    )}
                    {detail.status === "Rejected" && (
                      <span className="p-1.5 text-xs font-medium uppercase tracking-wider ml-2 text-red-800 bg-red-200 rounded-lg bg-opacity-50">
                        Rejected
                      </span>
                    )}
                    {detail.status === "Pending" && (
                      <span className="p-1.5 text-xs font-medium uppercase tracking-wider ml-2 text-yellow-800 bg-yellow-200 rounded-lg bg-opacity-50">
                        Pending
                      </span>
                    )}
                  </dd>
                </div>
                <div className="flex flex-col pt-3">
                  <dt className="mb-1 text-gray-500 md:text-sm">
                    Second Lecturer
                  </dt>
                  <dd className="text-sm font-semibold">
                    {detail.second_lecturer_email}
                    {detail.second_status === "Approved" &&
                      detail.second_lecturer_email && (
                        <span className="p-1.5 text-xs font-medium uppercase tracking-wider ml-2 text-green-800 bg-green-200 rounded-lg bg-opacity-50">
                          Approved
                        </span>
                      )}
                    {detail.second_status === "Rejected" &&
                      detail.second_lecturer_email && (
                        <span className="p-1.5 text-xs font-medium uppercase tracking-wider ml-2 text-red-800 bg-red-200 rounded-lg bg-opacity-50">
                          Rejected
                        </span>
                      )}
                    {detail.second_status === "Pending" &&
                      detail.second_lecturer_email && (
                        <span className="p-1.5 text-xs font-medium uppercase tracking-wider ml-2 text-yellow-800 bg-yellow-200 rounded-lg bg-opacity-50">
                          Pending
                        </span>
                      )}
                  </dd>
                </div>
              </dl>
              <dl className="max-w-md text-gray-900 divide-y divide-gray-200">
                <div className="flex flex-col pb-3">
                  <dt className="mb-1 text-gray-500 md:text-sm">Course Code</dt>
                  <dd className="text-sm font-semibold">
                    {detail.course_code}
                  </dd>
                </div>
                <div className="flex flex-col py-3">
                  <dt className="mb-1 text-gray-500 md:text-sm">Course Name</dt>
                  <dd className="text-sm font-semibold">
                    {detail.course_name}
                  </dd>
                </div>
                <div className="flex flex-col pt-3">
                  <dt className="mb-1 text-gray-500 md:text-sm">Student</dt>
                  <dd className="text-sm font-semibold">
                    {detail.student_email}
                  </dd>
                </div>
              </dl>
              <dl className="max-w-md text-gray-900 divide-y divide-gray-200">
                <div className="flex flex-col pb-3">
                  <dt className="mb-1 text-gray-500 md:text-sm">
                    Meeting Number
                  </dt>
                  <dd className="text-sm font-semibold">
                    {detail.meeting_number}
                  </dd>
                </div>
                <div className="flex flex-col py-3">
                  <dt className="mb-1 text-gray-500 md:text-sm">Clock Out</dt>
                  <dd className="text-sm font-semibold">
                    {detail.check_out_date}
                  </dd>
                </div>
                <div className="flex flex-col pt-3">
                  <dt className="mb-1 text-gray-500 md:text-sm">Clock In</dt>
                  <dd className="text-sm font-semibold">
                    {detail.check_in_date}
                  </dd>
                </div>
              </dl>
              <dl className="max-w-md text-gray-900 divide-y divide-gray-200">
                <div className="flex flex-col pb-3">
                  <dt className="mb-1 text-gray-500 md:text-sm">Status</dt>
                  <dd className="text-sm font-semibold">
                    {status === "Approved" && (
                      <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-green-800 bg-green-200 rounded-lg bg-opacity-50">
                        Approved
                      </span>
                    )}
                    {status === "Rejected" && (
                      <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-red-800 bg-red-200 rounded-lg bg-opacity-50">
                        Rejected
                      </span>
                    )}
                    {status === "Pending" && (
                      <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-yellow-800 bg-yellow-200 rounded-lg bg-opacity-50">
                        Pending
                      </span>
                    )}
                  </dd>
                </div>
                <div className="flex flex-col py-3">
                  <dt className="mb-1 text-gray-500 md:text-sm">
                    Status Reason
                  </dt>
                  <dd className="text-sm font-semibold">
                    {detail.status_reason} : {detail.second_status_reason}
                  </dd>
                </div>
                <div className="flex flex-col pt-3">
                  <dt className="mb-1 text-gray-500 md:text-sm">
                    Student Comments
                  </dt>
                  <dd className="text-sm font-semibold">{detail.comments}</dd>
                </div>
              </dl>
              <dl className="max-w-md text-gray-900 divide-y divide-gray-200">
                <div className="flex flex-col pt-3 gap-1">
                  <dt className="text-gray-500 md:text-sm">PDF File: </dt>
                  <button
                    onClick={downloadAllFiles}
                    className="text-sm text-red-500 font-semibold"
                  >
                    Download All
                  </button>
                </div>
              </dl>
            </div>

            <div className="overflow-auto w-full rounded-lg mt-16">
              <div className="flex justify-between items-center mb-3">
                <div className="">
                  <h1 className="text-lg font-semibold">Assets Detail</h1>
                </div>
                <div className="flex flex-col justify-center items-end">
                  {!authorized &&
                    status == "Approved" &&
                    detail?.bookings?.[0]?.assets.length == null && (
                      <a
                        href={`/assets/${id}`}
                        // onClick={(e) =>
                        //   new Date(detail.check_out_date) <= new Date() &&
                        //   e.preventDefault()
                        // }
                        className={`${
                          // new Date(detail.check_out_date) <= new Date()
                          false
                            ? "bg-gray-200 hover:bg-gray-300 text-gray-800 hover:cursor-not-allowed"
                            : "bg-blue-200 hover:bg-blue-300 text-blue-800 hover:cursor-pointer"
                        } font-bold py-2 px-4 rounded m-2`}
                      >
                        + Book Assets
                      </a>
                    )}

                  {/* Lecturer sudah Approved dan sudah ngebook */}
                  {detail?.bookings?.[0]?.assets.length != null && (
                    <div>
                      {detail?.bookings?.[0]?.status === "Approved" && (
                        <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-green-800 bg-green-200 rounded-lg bg-opacity-50 w-fit h-fit">
                          {detail?.bookings?.[0]?.status}{" "}
                          <span>#{detail?.bookings?.[0]?.id}</span>
                        </span>
                      )}
                      {detail?.bookings?.[0]?.status === "Rejected" && (
                        <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-red-800 bg-red-200 rounded-lg bg-opacity-50 w-fit h-fit">
                          {detail?.bookings?.[0]?.status}{" "}
                          <span>#{detail?.bookings?.[0]?.id}</span>
                        </span>
                      )}
                      {detail?.bookings?.[0]?.status === "Pending" && (
                        <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-yellow-800 bg-yellow-200 rounded-lg bg-opacity-50 w-fit h-fit">
                          {detail?.bookings?.[0]?.status}{" "}
                          <span>#{detail?.bookings?.[0]?.id}</span>
                        </span>
                      )}
                      <h1 className="text-xs font-semibold my-1">
                        Created: {detail?.bookings?.[0]?.created_at}
                      </h1>
                      <h1 className="text-xs font-semibold my-1">
                        Updated: {detail?.bookings?.[0]?.updated_at}
                      </h1>
                    </div>
                  )}

                  {/* Dosen yang mau change status */}
                  {authorized &&
                    ((firstSecondLecturer == 1 && detail.status == "Pending") ||
                      (firstSecondLecturer == 2 &&
                        detail.second_status == "Pending")) &&
                    detail?.bookings?.[0]?.assets.length == null && (
                      <form>
                        <div className="flex items-center gap-3">
                          <div>
                            <input
                              className={`w-full border-2 rounded-xl p-3 my-2 ${
                                errorMessage
                                  ? "border-red-500"
                                  : "border-gray-300"
                              }`}
                              placeholder="Reason"
                              type="text"
                              onChange={(e) => setStatusReason(e.target.value)}
                              value={statusReason}
                              required
                            />
                          </div>
                          <button
                            type="button"
                            onClick={() => handleSubmit("Approved")}
                            className="w-fit h-full text-white bg-green-500 hover:bg-green-600 active:scale-[.9] font-medium rounded-lg text-sm px-5 py-3"
                          >
                            Approved
                          </button>
                          <button
                            type="button"
                            onClick={() => handleSubmit("Rejected")}
                            className="w-fit h-full text-white bg-red-500 hover:bg-red-600 active:scale-[.9] font-medium rounded-lg text-sm px-5 py-3"
                          >
                            Rejected
                          </button>
                        </div>
                      </form>
                    )}
                </div>
              </div>
            </div>
            <hr className="w-full border-[1px] mb-7" />

            <div className="flex flex-wrap gap-10 justify-center">
              {detail?.bookings?.[0]?.assets?.map((asset) => (
                <div
                  className="relative w-fit max-w-[280px] h-[35rem] bg-white border border-gray-200 rounded-lg shadow flex flex-col items-center"
                  key={asset.id}
                >
                  <a href="#">
                    <img
                      className="p-0 rounded-t-lg w-full max-h-[25rem]"
                      src={
                        asset?.images[0]
                          ? asset.images[0].path
                          : "/assets/noImage.jpg"
                      }
                      alt="product image"
                    />
                  </a>
                  <div className="px-5 py-5">
                    <div href="#">
                      <h5 className="text-md font-semibold tracking-tight text-gray-500">
                        #{asset.id} / {asset.product_number}
                      </h5>
                    </div>
                    <div href="#">
                      <h5 className="text-lg font-semibold tracking-tight text-gray-900">
                        {asset.name}
                      </h5>
                    </div>
                    <div href="#">
                      <h5 className="text-sm font-semibold tracking-tight text-gray-400">
                        Created: {asset.created_at}
                      </h5>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Detail;
